<template>
  <div
    class="flex flex-col"
    :class="containerStyleClasses ?? 'w-full'"
  >
    <div
      class="flex w-full my-1"
      :class="[arrangeLabelAndInputVertically? 'flex-col items-start justify-center' : 'flex-row items-center justify-start']"
    >
      <label
        v-if="labelText"
        :for="fieldName"
        :class="[labelStyleClasses, arrangeLabelAndInputVertically? 'my-1.5' : 'mr-2', 'text-sm']"
      >{{
        labelText
      }}</label>
      <p v-show="showSubLabelText" class="text-xs md:text-sm text-a-secondary font-semibold mb-1">{{subLabelText}}</p>
      <div class="flex flex-row w-full items-center">
        <input
          :value="modelValue"
          @input="emitInput"
          @blur="$event => onBlur($event)"
          :readonly="isReadOnly"
          :name="fieldName"
          :class="[
            'w-full h-10 border rounded px-2 focus:outline-none ',
            errors.length > 0
              ? 'border-a-error'
              : 'border-a-neutral-dim hover:border-a-neutral-lightest focus:border-a-primary',
            isReadOnly
              ? 'bg-a-neutral-dim focus:border-transparent'
              : 'bg-white',
            tailwindClasses,
          ]"
          :autofocus="isAutoFocused"
        />
        <template v-if="showErrorIcon">
          <span v-show="errors.length > 0">
            <Icon
            name="mdi:close-circle"
            class="text-lg text-a-error mx-2"
            />
          </span>
        </template>
      </div>
    </div>
    <div class="flex flex-row w-full">
      <span class="text-a-error text-xs md:text-sm flex flex-row items-center">
        {{ errors[0] }}</span>
      <span
        class="ml-auto text-sm"
        v-if="hasLengthCounter"
        :class="[inputLength > maxLengthForCounter ? 'text-a-error' : 'text-a-success']"
      >{{ inputLength }}/{{
        maxLengthForCounter
      }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits([
  "update:model-value",
]);

const props = withDefaults(defineProps<{
  modelValue: string | number | null;
  labelText?: string;
  subLabelText?: string;
  showSubLabelText?: boolean;
  labelStyleClasses?: string;
  arrangeLabelAndInputVertically?: boolean;
  fieldName: string;
  validationLabel: string;
  fieldType?: string;
  hasLengthCounter?: boolean;
  maxLengthForCounter?: number;
  validationRules?: string;
  isReadOnly?: boolean;
  tailwindClasses?: string;
  containerStyleClasses?: string;
  showErrorIcon?: boolean;
  isAutoFocused?: boolean;
}>(), {
  labelText: "",
  subLabelText: 'Please fill the field',
  showSubLabelText: false,
  labelStyleClasses: "",
  fieldType: "text",
  validationRules: "",
  isReadOnly: false,
  maxLengthForCounter: 70,
  tailwindClasses: "",
  containerStyleClasses: "",
  showErrorIcon: true,
  arrangeLabelAndInputVertically: false,
  isAutoFocused: false
});

function onBlur(event: Event) {
  handleBlur(event, true);
}

const { value, errors, handleBlur } = useField(() => {
  return props.fieldName
}, props.validationRules, { syncVModel: true, label: props.validationLabel });


const inputLength = computed(() => {
  return props.modelValue ? (props.modelValue as string)?.length : 0;
});

function emitInput(event: Event) {
  const element = event.target as HTMLInputElement;
  emit(
    "update:model-value",
    element.value
  );
}
</script>
<style></style>
